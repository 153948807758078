<h1 mat-dialog-title>Generating Shipments</h1>

<div mat-dialog-content>
  <div class="row padding-left-15 padding-right-15">
    <div class="col-md-12 padding-top-10">There are {{ data.sposForShipmentsCount }} SPOs that can generate a Shipment.</div>

    <div class="col-md-12 padding-top-10" *ngIf="data.noShipmentSpos != 0">
      {{ data.noShipmentSpos }} of those SPOs do not have Shipments.
    </div>

    <div *ngIf="existShipments">
      <div class="col-md-12 padding-top-10">This Lot already has Shipments:</div>
      <div class="col-md-12 status-list padding-top-10" *ngFor="let status of shipmentsStatuses">
        {{ status }}: {{ data.shipmentsCount[status] }}
      </div>
    </div>

    <ng-container *ngIf="!existShipments">
      <div class="col-md-12 padding-top-10">There are no generated Shipments yet.</div>
    </ng-container>

    <ng-container *ngIf="existShipments">
      <div class="col-md-12 padding-top-10" *ngIf="data.canDropAllShipments">All Shipments can be dropped.</div>

      <ng-container *ngIf="!data.canDropAllShipments">
        <div class="col-md-12 padding-top-10" *ngIf="data.droppableShipments === 0">The existing Shipments cannot be dropped.</div>
        <div class="col-md-12 padding-top-10" *ngIf="data.droppableShipments !== 0">
          {{ data.droppableShipments }} Shipments can be dropped.
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<div mat-dialog-actions>
  <div class="row width-100per">
    <div class="col-md-12 text-center">
      <ng-container *ngIf="existShipments">
        <button class="btn-product margin-right-15" (click)="dropAndGenerateShipments()" *ngIf="data.canDropAllShipments">
          Drop and recreate
        </button>

        <button
          class="btn-product margin-right-15"
          (click)="dropOnlyCancelableShipments()"
          *ngIf="!data.canDropAllShipments && data.droppableShipments !== 0"
        >
          Drop only cancelable
        </button>

        <button
          class="btn-product margin-right-15"
          (click)="generateMissingShipments()"
          *ngIf="data.noShipmentSpos != 0 || data.noShipmentFulfilments != 0"
        >
          Generate only missing
        </button>
      </ng-container>

      <button
        class="btn-product margin-right-15"
        (click)="generateShipments()"
        *ngIf="!existShipments && (data.sposForShipmentsCount != 0 || data.existsWarehouseFulfilments)"
      >
        Generate
      </button>

      <button class="btn-product" [mat-dialog-close]="null">Cancel</button>
    </div>
  </div>
</div>
