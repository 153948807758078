import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenerateShipmentsRecap } from '../../../../models/generate-shipments-recap-model';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { GenerateShipmentsEntitiesEnum } from '../../../../models/generate-shipments-entities-enum';

@Component({
  selector: 'app-generate-shipments-recap-modal',
  templateUrl: './generate-shipments-recap-modal.component.html',
  styleUrls: ['./generate-shipments-recap-modal.component.css'],
})
export class GenerateShipmentsRecapModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GenerateShipmentsRecapModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenerateShipmentsRecap,
    private dialog: MatDialog
  ) {}

  existShipments: boolean;

  shipmentsStatuses: string[];

  private totalShipments = 0;

  ngOnInit() {
    this.shipmentsStatuses = Object.keys(this.data.shipmentsCount);
    this.existShipments = this.shipmentsStatuses.length > 0;

    for (const key of this.shipmentsStatuses) {
      this.totalShipments += this.data.shipmentsCount[key] as number;
    }
  }

  generateShipments() {
    if (this.data.sposForShipmentsCount === 0 && this.data.existsWarehouseFulfilments) {
      this.askForConfirmation(
        'No SPOs can generate Shipments. The generation will only work from WarehouseFulfilments.',
        GenerateShipmentsEntitiesEnum.GENERATE
      );
    } else if (this.data.sposForShipmentsCount !== 0 && !this.data.existsWarehouseFulfilments) {
      this.askForConfirmation(
        `This will generate ${this.data.sposForShipmentsCount} Shipments from SPOs.`,
        GenerateShipmentsEntitiesEnum.GENERATE
      );
    } else if (this.data.sposForShipmentsCount !== 0 && this.data.existsWarehouseFulfilments) {
      this.askForConfirmation(
        `This will generate Shipments from both SPOs (${this.data.sposForShipmentsCount} Shipments) and WarehouseFulfilments.`,
        GenerateShipmentsEntitiesEnum.GENERATE
      );
    }
  }

  dropAndGenerateShipments() {
    this.askForConfirmation(
      `This will drop all the ${this.totalShipments} existing Shipments and will create ${this.data.sposForShipmentsCount} new ones.`,
      GenerateShipmentsEntitiesEnum.DROP_GENERATE
    );
  }

  dropOnlyCancelableShipments() {
    this.askForConfirmation(
      // eslint-disable-next-line
      `This will drop only the ${this.data.droppableShipments} droppable Shipments and will regenerate them. The other ones will be left untouched.`,
      GenerateShipmentsEntitiesEnum.DROP_ONLY_CANCELABLE
    );
  }

  generateMissingShipments() {
    this.askForConfirmation(
      `This will generate the ${this.data.noShipmentSpos} missing Shipments from SPO and ${this.data.noShipmentFulfilments} ` +
        `missing Shipments from Warehouse Fulfilments. No existing Shipment will be modified.`,
      GenerateShipmentsEntitiesEnum.GENERATE_MISSING
    );
  }

  private askForConfirmation(message: string, entity: GenerateShipmentsEntitiesEnum) {
    return this.dialog
      .open(GenericConfirmationModalComponent, { data: message })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.dialogRef.close(entity);
        }
      });
  }
}
