import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('GenerateShipmentsRecap')
export class GenerateShipmentsRecap {
  @JsonProperty('existsWarehouseFulfilments', Boolean, true) existsWarehouseFulfilments: boolean = undefined;
  @JsonProperty('sposForShipmentsCount', Number, true) sposForShipmentsCount: number = undefined;
  @JsonProperty('noShipmentSpos', Number, true) noShipmentSpos: number = undefined;
  @JsonProperty('noShipmentFulfilments', Number, true) noShipmentFulfilments: number = undefined;
  @JsonProperty('shipmentsCount', Any, true) shipmentsCount: any = undefined;
  @JsonProperty('canDropAllShipments', Boolean, true) canDropAllShipments: boolean = undefined;
  @JsonProperty('droppableShipments', Number, true) droppableShipments: number = undefined;
}
