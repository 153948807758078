<mat-toolbar id="subsectionMenu" color="accent">
  <span
    class="padding-left-100 lot-name"
    [matTooltip]="'Date: ' + (currentLot | async)?.date + '   Status: ' + (currentLot | async)?.status"
    matTooltipClass="lot-tooltip"
  >
    {{ (currentLot | async)?.name }}
  </span>

  <span class="fill-remaining-space">
    <button class="btn-lot-edit-menu" [routerLink]="['/lot-edit', id, 'po']" routerLinkActive="active">
      <i class="fa fa-pencil-square-o padding-bottom-1" aria-hidden="true"></i>
      PO
    </button>
    <button class="btn-lot-edit-menu" [routerLink]="['/lot-edit', id, 'po-det']" routerLinkActive="active">
      <i class="fa fa-list-ul padding-bottom-1" aria-hidden="true"></i>
      PO Items
    </button>
    <button class="btn-lot-edit-menu" [routerLink]="['/lot-edit', id, 'warehouse-fulfilment']" routerLinkActive="active">
      <i class="fa fa-cube padding-bottom-1" aria-hidden="true"></i>
      Warehouse
    </button>
    <button class="btn-lot-edit-menu" [routerLink]="['/lot-edit', id, 'supplier-availabilities']" routerLinkActive="active">
      <i class="fa fa-address-book padding-bottom-1" aria-hidden="true"></i>
      Supplier Availabilities
    </button>
    <button class="btn-lot-edit-menu" [routerLink]="['/lot-edit', id, 'spo']" routerLinkActive="active">
      <i class="fa fa-user padding-bottom-1" aria-hidden="true"></i>
      SPOs
    </button>
    <button class="btn-lot-edit-menu" [routerLink]="['/lot-edit', id, 'spo-det']" routerLinkActive="active">
      <i class="fa fa-address-card" aria-hidden="true"></i>
      SPO Items
    </button>
    <button class="btn-lot-edit-menu" [routerLink]="['/lot-edit', id, 'log-discarded-value']" routerLinkActive="active">
      <i class="fa fa-file padding-bottom-1" aria-hidden="true"></i>
      Log Discarded Value
    </button>
    <button class="btn-lot-edit-menu" [routerLink]="['/lot-edit', id, 'shipments']" routerLinkActive="active">
      <i class="fa fa-truck padding-bottom-1" aria-hidden="true"></i>
      Shipments
    </button>
    <button class="btn-lot-edit-menu" [routerLink]="['/lot-edit', id, 'shipments-det']" routerLinkActive="active">
      <i class="fa fa-tasks padding-bottom-1" aria-hidden="true"></i>
      Shipments Details
    </button>
  </span>

  <span style="margin-right: 115px">
    <ng-container *ngIf="fulfilmentJobInProgress && fulfilmentJobStatusUpdated">
      <app-fulfilment-job-progress
        [updatedStatus]="fulfilmentJobStatusUpdated"
        (jobCompleted)="fulfilmentJobCompleted()"
        (jobStuck)="fulfilmentJobStuck()"
        (jobRetry)="fulfilmentJobRetry()"
      ></app-fulfilment-job-progress>
    </ng-container>

    <ng-container *ngIf="generateOrderedFromLsaJobInProgress && generateOrderedFromLsaJobUpdated">
      <app-generate-ordered-from-lsa-job-progress
        [updatedStatus]="generateOrderedFromLsaJobUpdated"
        (jobCompleted)="generateOrderedFromLsaJobCompleted()"
        (jobStuck)="generateOrderedFromLsaJobStuck()"
        (jobRetry)="generateOrderedFromLsaJobRetry()"
      ></app-generate-ordered-from-lsa-job-progress>
    </ng-container>

    <ng-container *ngIf="generateOrderedFromSpoJobInProgress && generateOrderedFromSpoJobUpdated">
      <app-generate-ordered-from-spo-job-progress
        [updatedStatus]="generateOrderedFromSpoJobUpdated"
        (jobCompleted)="generateOrderedFromSpoJobCompleted()"
        (jobStuck)="generateOrderedFromSpoJobStuck()"
        (jobRetry)="generateOrderedFromSpoJobRetry()"
      ></app-generate-ordered-from-spo-job-progress>
    </ng-container>

    <button
      *ngIf="(hasFulfillmentCreation | async) || (hasShipmentCreation | async)"
      class="btn-lot-edit-menu"
      [matMenuTriggerFor]="generateMenu"
    >
      <i class="fa fa-cogs margin-right-6" aria-hidden="true"></i> Generate
    </button>

    <mat-menu #generateMenu="matMenu">
      <button *ngIf="hasFulfillmentCreation | async" mat-menu-item [disabled]="fulfilmentInProgress == 1" (click)="generateFulfilment()">
        Generate Fulfilment
      </button>
      <button
        *ngIf="hasFulfillmentCreation | async"
        mat-menu-item
        [disabled]="fulfilmentInProgress == 1"
        (click)="regenerateWarehouseFulfilment()"
      >
        Regenerate Warehouse Fulfilment
      </button>

      <button *ngIf="hasFulfillmentCreation | async" mat-menu-item [matMenuTriggerFor]="generateOrdered">
        Generate ordered quantities
      </button>
      <mat-menu #generateOrdered="matMenu">
        <button mat-menu-item (click)="generateOrderedFromLSA(false)">
          <i class="fa fa-address-book padding-bottom-1 padding-right-5" aria-hidden="true"></i>From Supplier Availabilities
        </button>
        <button mat-menu-item (click)="generateOrderedFromLSA(true)">
          <i class="fa fa-address-book padding-bottom-1 padding-right-5" aria-hidden="true"></i>From Selected Supplier Availabilities
        </button>
        <button mat-menu-item (click)="generateOrderedFromSPO()">
          <i class="fa fa-user padding-bottom-1 padding-right-5" aria-hidden="true"></i>From SPOs
        </button>

        <button
          mat-menu-item
          (click)="manualGenerateOrderedFromConfiguration()"
          *ngIf="(currentLot | async)?.lotConfigurationId && (hasAdmin | async)"
        >
          <i class="fa fa-area-chart padding-bottom-1 padding-right-5" aria-hidden="true"></i>From Configuration
        </button>

        <button mat-menu-item [routerLink]="['/lot-edit', id, 'generate-ordered-results']">
          <i class="fa fa-table padding-bottom-1 padding-right-5" aria-hidden="true"></i>Results
        </button>
      </mat-menu>

      <button *ngIf="hasShipmentCreation | async" mat-menu-item (click)="generateShipments()">Create Shipments</button>
    </mat-menu>

    <button *ngIf="hasLotManagement | async" class="btn-lot-edit-menu" [matMenuTriggerFor]="editLot">
      <i class="fa fa-edit margin-right-6" aria-hidden="true"></i>Edit Lot
    </button>
    <mat-menu #editLot="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="changeStatus">
        <i class="fa fa-check-square-o padding-bottom-1 padding-right-5" aria-hidden="true"></i>Change Status
      </button>
      <mat-menu #changeStatus="matMenu">
        <button [disabled]="(currentLot | async)?.status == 'Open'" mat-menu-item (click)="changeLotStatus('Open')">Open</button>
        <button [disabled]="(currentLot | async)?.status != 'Open'" mat-menu-item (click)="changeLotStatus('Closed')">Closed</button>
      </mat-menu>
      <button mat-menu-item (click)="deleteLot()">
        <i class="fa fa-trash padding-bottom-1 padding-right-5" aria-hidden="true"></i>Delete Lot
      </button>
    </mat-menu>

    <button class="btn-lot-edit-menu" (click)="goBack()" matTooltip="Back to Lots list">Exit</button>
  </span>
</mat-toolbar>
